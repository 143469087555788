import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { selectMenuListApi, storeMenuApi, updateMenuApi, deleteMenuApi } from './menuAPI';

// ဒါက Initial State
const initialState = {
    menus: [],
    status: 'idle', // idle, loading, error , succeeded
    error: null,
    errors: {}, // for user register form validation errors

    // status တွေတော့ ခွဲထားပြီး
    // error နဲ့ errors တွေကိုတော့ တစ်ခုတည်းမျှသုံးနေကြတာမျိူး
    // အစဉ်ပြေမလား?

    store_status: 'idle',
    update_status: 'idle',
    delete_status: 'idle',
    selected_menu: {
        "id": 9,
        "name": "menu two",
        "description": "this is description",
        "background_image_url": "http://localhost/qrmenu/public/storage/tLk7t4k2ywXDmcpaMcxkb0pRQ5cfWyULWo5CEbLu.jpg",
        "is_active": 1,
        "category_id": 6,
        "created_at": "2023-11-26T08:38:36.000000Z",
        "updated_at": "2023-11-26T08:38:36.000000Z",
        "media": [],
        "items": [
            {
                "id": 21,
                "name": "Item One",
                "description": "item one description",
                "background_image_url": "http://192.168.188.89/qrmenu/public/storage/ZpnZ6yikJPQewfwg1qjOO4L3ukmIMP8meQdnwCD4.jpg",
                "old_price": 10,
                "price": 8,
                "currency": "MMK",
                "current_price": 8,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 9,
                "created_at": "2023-11-26T08:45:42.000000Z",
                "updated_at": "2023-11-26T08:45:42.000000Z",
                "media": []
            },
            {
                "id": 22,
                "name": "Item Two",
                "description": "item two description",
                "background_image_url": "http://192.168.188.89/qrmenu/public/storage/3uMPiBeD9FfD2IzWA9i1WyE2POuYJ6pRrbSJAdPm.jpg",
                "old_price": 10,
                "price": 8,
                "currency": "MMK",
                "current_price": 8,
                "quantity": 1,
                "is_active": 1,
                "menu_id": 9,
                "created_at": "2023-11-26T08:45:49.000000Z",
                "updated_at": "2023-11-26T08:45:49.000000Z",
                "media": []
            }
        ]
    }
};

// Thunks
export const selectMenuListAsyncThunk = createAsyncThunk(
    'menus/select',
    async ({ access_token, page }, { getState }) => {
        const state = getState(); // <-- invoke and access state object
        if (state.menus.menus.length > 0 && page == 1) {
            return state.menus.menus;
        }
        const response = await selectMenuListApi({ access_token, page });
        return response.data;
    }
);
export const storeMenuAsyncThunk = createAsyncThunk(
    'menus/store',
    async (formData) => {
        const response = await storeMenuApi(formData);
        return response.data;
    }
);
export const updateMenuAsyncThunk = createAsyncThunk(
    'menus/update',
    async (formData) => {
        const response = await updateMenuApi(formData);
        return response.data;
    }
);
export const deleteMenuAsyncThunk = createAsyncThunk(
    'menus/delete',
    async (formData) => {
        const response = await deleteMenuApi(formData);
        return response.data;
    }
);

// Slice
export const menuSlice = createSlice({
    name: 'menus',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // two main data setters
        setMenus: (state, action) => {
            state.menus = action.payload;
        },
        setSelectedMenu: (state, action) => {
            state.selected_menu = action.payload;
        },

        
        // detail data updater
        updateSelectedMenu: (state, action) => {
            const { name, value } = action.payload;
            state.selected_menu[name] = value;
        },

        // status updater
        setMenuStatus: (state, action) => {
            state.status = action.payload;
        },
        setMenuStoreStatus: (state, action) => {
            state.store_status = action.payload;
        },
        setMenuUpdateStatus: (state, action) => {
            state.update_status = action.payload;
        },
        setMenuDeleteStatus: (state, action) => {
            state.delete_status = action.payload;
        },

        // set errors, this is for Clear Errors
        setMenuErrors: (state, action) => {
            state.errors = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // select menus thunk
            .addCase(selectMenuListAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(selectMenuListAsyncThunk.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.menus = action.payload;
            })
            .addCase(selectMenuListAsyncThunk.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error.message;
            })

            // store menu api
            .addCase(storeMenuAsyncThunk.pending, (state) => {
                state.store_status = 'loading';
            })
            .addCase(storeMenuAsyncThunk.fulfilled, (state, action) => {
                state.store_status = 'succeeded';
                state.menus.push(action.payload);
            })
            .addCase(storeMenuAsyncThunk.rejected, (state, action) => {
                state.store_status = 'error';
                // နှစ်ခုတည်းက တစ်ခု ယူရမှာ ။
                try {
                    // try multiple errors
                    state.errors = JSON.parse(action.error.message); // Store the error message
                    const firstKey = Object.keys(JSON.parse(action.error.message))[0];
                    const firstItem = JSON.parse(action.error.message)[firstKey][0];

                    state.error = firstItem;
                }
                catch (err) {
                    // error message
                    state.error = action.error.message; // Store the error message
                }
            })

            // update menu api
            .addCase(updateMenuAsyncThunk.pending, (state) => {
                state.update_status = 'loading';
            })
            .addCase(updateMenuAsyncThunk.fulfilled, (state, action) => {
                state.update_status = 'succeeded';
                // find and update local menu state
                state.selected_menu = action.payload;
            })
            .addCase(updateMenuAsyncThunk.rejected, (state, action) => {
                // handle multiple errors for remote validation
                state.update_status = 'error';
                try {
                    // try multiple errors
                    state.errors = JSON.parse(action.error.message); // Store the error message
                }
                catch (err) {
                    // error message
                    state.error = action.error.message; // Store the error message
                }
            })

            // delete menu api
            .addCase(deleteMenuAsyncThunk.pending, (state) => {
                state.delete_status = 'loading';
            })
            .addCase(deleteMenuAsyncThunk.fulfilled, (state, action) => {
                state.delete_status = 'succeeded';
                state.menus = state.menus.filter(menu => menu.id !== action.payload.id);
            })
            .addCase(deleteMenuAsyncThunk.rejected, (state, action) => {
                // handle multiple errors for remote validation
                state.delete_status = 'error';
                state.error = action.error.message; // Store the error message
                try {
                    state.errors = JSON.parse(action.error.message); // Store the error message
                }
                catch (error) {
                    state.errors = {};
                }
            });
    },
});

// actions
export const {
    setMenus,
    setSelectedMenu, updateSelectedMenu,
    setMenuStatus, setMenuStoreStatus, setMenuUpdateStatus, setMenuDeleteStatus,
    setMenuErrors
} = menuSlice.actions;

// selectors
/* Global State / Combined Reducers ကနေ select လုပ်နေတယ်ဆိုတာ သတိကပ်ထားရမယ် */
// main data (၂) မျိုး
export const selectMenuList = (state) => state.menus.menus;
export const selectSelectedMenu = (state) => state.menus.selected_menu;
// CRUD Status (၄)​ မျိုး 
export const selectMenuStatus = (state) => state.menus.status;
export const selectMenuStoreStatus = (state) => state.menus.store_status;
export const selectMenuUpdateStatus = (state) => state.menus.update_status;
export const selectMenuDeleteStatus = (state) => state.menus.delete_status;
// Error (၂)​ မျိုး
export const selectMenuError = (state) => state.menus.error;
export const selectMenuErrors = (state) => state.menus.errors;

// export reducer
export default menuSlice.reducer;