import axios from 'axios';

import { SHOP_API_ENDPOINT, WAITING_TIME } from 'config/config';

// GET REQUEST
export function selectShopListApi({ access_token, page }) {
  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };

    // console.log("selectShopLIstApi");
    // console.log(config);
    axios
      .get(SHOP_API_ENDPOINT + '?page=' + page, config)
      .then((response) => {
        setTimeout(() => resolve(response.data), WAITING_TIME);
        //resolve(response.data);
      })
      .catch((error) => {
        setTimeout(() => reject(error), WAITING_TIME);
        // reject(error);
      });
  });
}

export function storeShopApi(formData) {
  console.log('storeShopApi');
  console.log(formData);
  const access_token = formData.get('access_token');
  const config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .post(SHOP_API_ENDPOINT, formData, config)
      .then((response) => {
        setTimeout(() => resolve(response.data), WAITING_TIME);
        //resolve(response.data);
      })
      .catch((error) => {
        // setTimeout(() => reject(error), 3000);
        // reject(error);
        console.error('shopAPI->storeShopApi failed:', error);

        if (error.response) {
          // The request was made, but the server responded with a status code other than 2xx
          console.error('Server responded with status code:', error.response.status);
          console.error('Response data:', error.response.data);
          console.log(error.response.data);
          console.log(error.response.data.errors);
          // သိပ်ပြီးစိတ်ချမနေနဲ့ errors ဆိုတာ ပါချင်မှ ပါမယ်။
          // undefined  ဖြစ်နေရင် run time မှာ ရပ်သွားလိမ့်မယ်။
          // handle လုပ်ထား

          // message ပါရင်ပါမယ် ၊​ မပါရင် မပါဘူး
          // errors ဆိုလည်း ထိုနည်းလည်းကောင်း
          let errors = error.response.data.errors;
          let single_message = error.response.data.message;

          if (typeof errors !== 'undefined') {
            console.log('errors is not undefiend');
            // handle parse
            try {
              reject({ message: JSON.stringify(errors) });
            } catch (exp) {}
          }
          if (typeof single_message !== 'undefined') {
            reject(single_message);
          }
          // အပေါ်မှာ stop point နှစ်ခု ရှိတယ်။ အဲ့မှာ မရပ်ခဲ့ရင် ဒီမှာ ရပ်မယ်။
          // ကျွန်တော်မျိုးလဲ မသိပါဆိုတာမျိုး။
          reject('Something went wrong!');
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received. The request may have failed.');
          reject('No response received');
        } else {
          // Something else went wrong
          console.error('An error occurred while making the request:', error.message);
          reject('Request failed: ' + error.message);
        }
      });
  });
}

export function updateShopApi(formData) {
  console.log('updateShopApi');
  console.log(formData);
  const access_token = formData.get('access_token');
  const id = formData.get('id');
  const config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .post(SHOP_API_ENDPOINT + '/' + id, formData, config)
      .then((response) => {
        setTimeout(() => resolve(response.data), WAITING_TIME);
        //resolve(response.data);
      })
      .catch((error) => {
        // setTimeout(() => reject(error), 3000);
        // reject(error);
        console.error('shopAPI->updateShopApi failed:', error);

        if (error.response) {
          // The request was made, but the server responded with a status code other than 2xx
          console.error('Server responded with status code:', error.response.status);
          console.error('Response data:', error.response.data);
          console.log(error.response.data); // ဒါမျိုးလည်း ဖြစ်နေနိုင်တယ် ->  { "message": "Unauthenticated." }
          console.log(error.response.data.errors); // may be undefined
          // we need to handle single / multiple error
          // reject({ "message": JSON.stringify(error.response.data.errors) }); // Don't do like that, since u don't know data.errors is existed or not
          // message ပါရင်ပါမယ် ၊​ မပါရင် မပါဘူး
          // errors ဆိုလည်း ထိုနည်းလည်းကောင်း
          let errors = error.response.data.errors;
          let single_message = error.response.data.message;

          if (typeof errors !== 'undefined') {
            console.log('errors is not undefiend');
            // handle parse
            try {
              reject({ message: JSON.stringify(errors) });
            } catch (exp) {}
          }
          if (typeof single_message !== 'undefined') {
            reject(single_message);
          }
          // အပေါ်မှာ stop point နှစ်ခု ရှိတယ်။ အဲ့မှာ မရပ်ခဲ့ရင် ဒီမှာ ရပ်မယ်။
          // ကျွန်တော်မျိုးလဲ မသိပါဆိုတာမျိုး။
          reject('Something went wrong!');
          // ဒီတစ်ပိုင်းမှာတင် ပြန်တဲ့ type က နှစ်မျိုး ဖြစ်နေတယ်။ single message လား? multiple message လားဆိုတာ သိဖို့ခက်ပြီ နောက်တစ်ဆင့်မှာ။
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received. The request may have failed.');
          reject('No response received');
        } else {
          // Something else went wrong
          console.error('An error occurred while making the request:', error.message);
          reject('Request failed: ' + error.message);
        }
      });
  });
}

export function deleteShopApi(formData) {
  console.log('deleteShopApi');
  console.log(formData);
  const access_token = formData.get('access_token');
  const id = formData.get('id');
  const config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .delete(SHOP_API_ENDPOINT + '/' + id, config)
      .then((response) => {
        setTimeout(() => resolve(response.data), WAITING_TIME);
        //resolve(response.data);
      })
      .catch((error) => {
        // setTimeout(() => reject(error), 3000);
        // reject(error);
        console.error('shopAPI->updateShopApi failed:', error);

        if (error.response) {
          // The request was made, but the server responded with a status code other than 2xx
          console.error('Server responded with status code:', error.response.status);
          console.error('Response data:', error.response.data);
          console.log(error.response.data);
          console.log(error.response.data.errors);

          let errors = error.response.data.errors;
          let single_message = error.response.data.message;

          if (typeof errors !== 'undefined') {
            console.log('errors is not undefiend');
            // handle parse
            try {
              reject({ message: JSON.stringify(errors) });
            } catch (exp) {}
          }
          if (typeof single_message !== 'undefined') {
            reject(single_message);
          }
          // အပေါ်မှာ stop point နှစ်ခု ရှိတယ်။ အဲ့မှာ မရပ်ခဲ့ရင် ဒီမှာ ရပ်မယ်။
          // ကျွန်တော်မျိုးလဲ မသိပါဆိုတာမျိုး။
          reject('Something went wrong!');
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received. The request may have failed.');
          reject('No response received');
        } else {
          // Something else went wrong
          console.error('An error occurred while making the request:', error.message);
          reject('Request failed: ' + error.message);
        }
      });
  });
}
