import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  selectShopListAsyncThunk,
  storeShopAsyncThunk,
  updateShopAsyncThunk,
  deleteShopAsyncThunk,
} from 'features/shop/shopSlice';
import {
  storeCategoryAsyncThunk,
  updateCategoryAsyncThunk,
  deleteCategoryAsyncThunk,
} from 'features/category/categorySlice';
import {
  storeMenuAsyncThunk,
  updateMenuAsyncThunk,
  deleteMenuAsyncThunk,
} from 'features/menu/menuSlice';
import {
  storeItemAsyncThunk,
  updateItemAsyncThunk,
  deleteItemAsyncThunk,
} from 'features/item/itemSlice';
// ဒါက Initial State
const initialState = {
  shops: [
    {
      id: 3,
      name: 'ရွှေမြန်မာ',
      background_image_url: 'https://qrmenu.sirv.com/Images/qr-menu/1-cover-image.jpg',
      shop_image_url: 'https://qrmenu.sirv.com/Images/qr-menu/2-shop-logo.png',
      description: 'မြန်မာ ဘူဖေး ထမင်းဆိုင်',
      address: '၇၁လမ်း၊ ၂၈x၂၉လမ်း၊ ပတ်ကုန်းဝန်းကျင်ရပ်ကွက်၊ ချမ်းအေးသာစံမြို့နယ်၊ မန္တ‌လေး။',
      phone: '094256856695',
      wifi_name: 'WifiParadise',
      wifi_password: 'h0meSw335',
      url: 'shop-one',
      selected: true,
      media: [
        {
          id: 6,
          file_name: 'picture6.png',
          file_url: 'picture6.png',
          file_type: 'picture',
          file_size: 1,
          created_at: '2023-12-15T02:14:44.000000Z',
          updated_at: '2023-12-15T02:14:44.000000Z',
        },
      ],
      categories: [
        {
          id: 6,
          name: 'ဖျော်ရည်',
          description: 'Category One Description',
          placement: 4,
          shop_id: 3,
          created_at: '2023-12-15T02:14:44.000000Z',
          updated_at: '2023-12-15T02:14:44.000000Z',
          media: {
            id: 7,
            file_name: 'picture7.png',
            file_url: 'picture7.png',
            file_type: 'picture',
            file_size: 1,
            created_at: '2023-12-15T02:14:44.000000Z',
            updated_at: '2023-12-15T02:14:44.000000Z',
          },
          menus: [
            {
              id: 9,
              name: 'ဆီချက်',
              description: 'Menu Seven Description',
              background_image_url:
                'https://qrmenu.sirv.com/Images/qr-menu/categories/category-seven.jpeg',
              is_active: 1,
              category_id: 6,
              created_at: '2023-12-15T02:14:44.000000Z',
              updated_at: '2023-12-15T02:14:44.000000Z',
              media: [],
              items: [
                {
                  id: 21,
                  name: 'ကြက်ကြော် (အစပ်)',
                  description: 'Item Six Description',
                  background_image_url: 'https://qrmenu.sirv.com/Images/qr-menu/menus/menu-19.jpeg',
                  old_price: 10,
                  price: 30,
                  currency: 'MMK',
                  current_price: 30,
                  quantity: 1,
                  is_active: 1,
                  menu_id: 9,
                  created_at: '2023-12-15T02:14:44.000000Z',
                  updated_at: '2023-12-15T02:14:44.000000Z',
                  media: [],
                },
                {
                  id: 22,
                  name: 'ကြက်ကြော် (အချို)',
                  description: 'Item Six Description',
                  background_image_url: 'https://qrmenu.sirv.com/Images/qr-menu/menus/menu-20.jpeg',
                  old_price: 10,
                  price: 30,
                  currency: 'MMK',
                  current_price: 30,
                  quantity: 1,
                  is_active: 1,
                  menu_id: 9,
                  created_at: '2023-12-15T02:14:44.000000Z',
                  updated_at: '2023-12-15T02:14:44.000000Z',
                  media: [],
                },
              ],
            },
            {
              id: 10,
              name: 'ရှမ်းခေါက်ဆွဲ',
              description: 'Menu Eight Description',
              background_image_url:
                'https://qrmenu.sirv.com/Images/qr-menu/categories/category-eight.jpeg',
              is_active: 1,
              category_id: 6,
              created_at: '2023-12-15T02:14:44.000000Z',
              updated_at: '2023-12-15T02:14:44.000000Z',
              media: [],
              items: [],
            },
          ],
        },
        {
          id: 7,
          name: 'သက်သက်လွတ်',
          description: 'Category One Description',
          placement: 5,
          shop_id: 3,
          created_at: '2023-12-15T02:14:44.000000Z',
          updated_at: '2023-12-15T02:14:44.000000Z',
          media: {
            id: 7,
            file_name: 'picture7.png',
            file_url: 'picture7.png',
            file_type: 'picture',
            file_size: 1,
            created_at: '2023-12-15T02:14:44.000000Z',
            updated_at: '2023-12-15T02:14:44.000000Z',
          },
          menus: [
            {
              id: 101,
              name: 'ထမင်းဖြူ',
              description: 'Menu Eight Description',
              background_image_url:
                'https://qrmenu.sirv.com/Images/qr-menu/categories/category-eight.jpeg',
              is_active: 1,
              category_id: 6,
              created_at: '2023-12-15T02:14:44.000000Z',
              updated_at: '2023-12-15T02:14:44.000000Z',
              media: [],
              items: [],
            },
          ],
        },
      ],
    },
    {
      id: 4,
      name: 'ငွေမြန်မာ',
      background_image_url:
        'https://graphicsfamily.com/wp-content/uploads/edd/2023/02/Restaurant-Logo-Design-2-1180x664.jpg',
      shop_image_url: 'https://logowik.com/content/uploads/images/restaurant9491.logowik.com.webp',
      description: 'Shop Two Description',
      address: 'Shop One Address',
      phone: 'Shop One Contact',
      wifi_name: 'WifiParadise',
      wifi_password: 'h0meSw335',
      url: 'shop-two',
      media: [],
      categories: [
        {
          id: 8,
          name: 'Fatima Burch',
          description: 'Ad quis repellendus',
          placement: 0,
          shop_id: 4,
          created_at: '2023-12-15T02:24:08.000000Z',
          updated_at: '2023-12-15T02:24:08.000000Z',
          media: null,
          menus: [
            {
              id: 11,
              name: 'Ivana Craft',
              description: 'Cupidatat amet arch',
              background_image_url:
                'http://localhost/qrmenu/public/storage/5jse876ltOkxoJv0D6ixmWAygZEocpJnz2IlARPQ.jpg',
              is_active: 1,
              category_id: 8,
              created_at: '2023-12-15T02:24:17.000000Z',
              updated_at: '2023-12-15T02:24:17.000000Z',
              media: [],
              items: [
                {
                  id: 23,
                  name: 'Katell Rivera',
                  description: 'Enim illum quos min',
                  background_image_url:
                    'http://localhost/qrmenu/public/storage/eli0jytTZIzr0Z0XmmTWqScsVrO5I4Sj6UlaQ4Hb.jpg',
                  old_price: 453,
                  price: 219,
                  currency: 'MMK',
                  current_price: 219,
                  quantity: 1,
                  is_active: 1,
                  menu_id: 11,
                  created_at: '2023-12-15T02:24:27.000000Z',
                  updated_at: '2023-12-15T02:24:27.000000Z',
                  media: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ], // app တစ်ခုလုံးရဲ့ အဓိက one and only state ပဲ။

  // page တွေအတွက် လိုတဲ့ state တွေ။
  selected_shop: {
    id: 3,
    name: 'ရွှေမြန်မာ',
    background_image_url: 'https://qrmenu.sirv.com/Images/qr-menu/1-cover-image.jpg',
    shop_image_url: 'https://qrmenu.sirv.com/Images/qr-menu/2-shop-logo.png',
    description: 'မြန်မာ ဘူဖေး ထမင်းဆိုင်',
    address: '၇၁လမ်း၊ ၂၈x၂၉လမ်း၊ ပတ်ကုန်းဝန်းကျင်ရပ်ကွက်၊ ချမ်းအေးသာစံမြို့နယ်၊ မန္တ‌လေး။',
    phone: '094256856695',
    wifi_name: 'WifiParadise',
    wifi_password: 'h0meSw335',
    url: 'shop-one',
    selected: true,
    media: [
      {
        id: 6,
        file_name: 'picture6.png',
        file_url: 'picture6.png',
        file_type: 'picture',
        file_size: 1,
        created_at: '2023-12-15T02:14:44.000000Z',
        updated_at: '2023-12-15T02:14:44.000000Z',
      },
    ],
    categories: [
      {
        id: 6,
        name: 'ဖျော်ရည်',
        description: 'Category One Description',
        placement: 4,
        shop_id: 3,
        created_at: '2023-12-15T02:14:44.000000Z',
        updated_at: '2023-12-15T02:14:44.000000Z',
        media: {
          id: 7,
          file_name: 'picture7.png',
          file_url: 'picture7.png',
          file_type: 'picture',
          file_size: 1,
          created_at: '2023-12-15T02:14:44.000000Z',
          updated_at: '2023-12-15T02:14:44.000000Z',
        },
        menus: [
          {
            id: 9,
            name: 'သံပုရာ',
            description: 'Menu Seven Description',
            background_image_url:
              'https://qrmenu.sirv.com/Images/qr-menu/categories/category-seven.jpeg',
            is_active: 1,
            category_id: 6,
            created_at: '2023-12-15T02:14:44.000000Z',
            updated_at: '2023-12-15T02:14:44.000000Z',
            media: [],
            items: [
              {
                id: 21,
                name: 'ကြက်ကြော် (အစပ်)',
                description: 'Item Six Description',
                background_image_url: 'https://qrmenu.sirv.com/Images/qr-menu/menus/menu-19.jpeg',
                old_price: 10,
                price: 30,
                currency: 'MMK',
                current_price: 30,
                quantity: 1,
                is_active: 1,
                menu_id: 9,
                created_at: '2023-12-15T02:14:44.000000Z',
                updated_at: '2023-12-15T02:14:44.000000Z',
                media: [],
              },
              {
                id: 22,
                name: 'ကြက်ကြော် (အချို)',
                description: 'Item Six Description',
                background_image_url: 'https://qrmenu.sirv.com/Images/qr-menu/menus/menu-20.jpeg',
                old_price: 10,
                price: 30,
                currency: 'MMK',
                current_price: 30,
                quantity: 1,
                is_active: 1,
                menu_id: 9,
                created_at: '2023-12-15T02:14:44.000000Z',
                updated_at: '2023-12-15T02:14:44.000000Z',
                media: [],
              },
            ],
          },
          {
            id: 10,
            name: 'ရှမ်းခေါက်ဆွဲ',
            description: 'Menu Eight Description',
            background_image_url:
              'https://qrmenu.sirv.com/Images/qr-menu/categories/category-eight.jpeg',
            is_active: 1,
            category_id: 6,
            created_at: '2023-12-15T02:14:44.000000Z',
            updated_at: '2023-12-15T02:14:44.000000Z',
            media: [],
            items: [],
          },
        ],
      },
      {
        id: 7,
        name: 'သက်သက်လွတ်',
        description: 'Category One Description',
        placement: 5,
        shop_id: 3,
        created_at: '2023-12-15T02:14:44.000000Z',
        updated_at: '2023-12-15T02:14:44.000000Z',
        media: {
          id: 7,
          file_name: 'picture7.png',
          file_url: 'picture7.png',
          file_type: 'picture',
          file_size: 1,
          created_at: '2023-12-15T02:14:44.000000Z',
          updated_at: '2023-12-15T02:14:44.000000Z',
        },
        menus: [
          {
            id: 9,
            name: 'ဂျပါတီ',
            description: 'Menu Seven Description',
            background_image_url:
              'https://qrmenu.sirv.com/Images/qr-menu/categories/category-seven.jpeg',
            is_active: 1,
            category_id: 6,
            created_at: '2023-12-15T02:14:44.000000Z',
            updated_at: '2023-12-15T02:14:44.000000Z',
            media: [],
            items: [
              {
                id: 21,
                name: 'ကြက်ကြော် (အစပ်)',
                description: 'Item Six Description',
                background_image_url: 'https://qrmenu.sirv.com/Images/qr-menu/menus/menu-19.jpeg',
                old_price: 10,
                price: 30,
                currency: 'MMK',
                current_price: 30,
                quantity: 1,
                is_active: 1,
                menu_id: 9,
                created_at: '2023-12-15T02:14:44.000000Z',
                updated_at: '2023-12-15T02:14:44.000000Z',
                media: [],
              },
              {
                id: 22,
                name: 'ကြက်ကြော် (အချို)',
                description: 'Item Six Description',
                background_image_url: 'https://qrmenu.sirv.com/Images/qr-menu/menus/menu-20.jpeg',
                old_price: 10,
                price: 30,
                currency: 'MMK',
                current_price: 30,
                quantity: 1,
                is_active: 1,
                menu_id: 9,
                created_at: '2023-12-15T02:14:44.000000Z',
                updated_at: '2023-12-15T02:14:44.000000Z',
                media: [],
              },
            ],
          },
          {
            id: 10,
            name: 'ရှမ်းခေါက်ဆွဲ',
            description: 'Menu Eight Description',
            background_image_url:
              'https://qrmenu.sirv.com/Images/qr-menu/categories/category-eight.jpeg',
            is_active: 1,
            category_id: 6,
            created_at: '2023-12-15T02:14:44.000000Z',
            updated_at: '2023-12-15T02:14:44.000000Z',
            media: [],
            items: [],
          },
        ],
      },
      {
        id: 8,
        name: 'မနက်စာ',
        description: 'Category One Description',
        placement: 4,
        shop_id: 3,
        created_at: '2023-12-15T02:14:44.000000Z',
        updated_at: '2023-12-15T02:14:44.000000Z',
        media: {
          id: 7,
          file_name: 'picture7.png',
          file_url: 'picture7.png',
          file_type: 'picture',
          file_size: 1,
          created_at: '2023-12-15T02:14:44.000000Z',
          updated_at: '2023-12-15T02:14:44.000000Z',
        },
        menus: [
          {
            id: 9,
            name: 'ဆီချက်',
            description: 'Menu Seven Description',
            background_image_url:
              'https://qrmenu.sirv.com/Images/qr-menu/categories/category-seven.jpeg',
            is_active: 1,
            category_id: 6,
            created_at: '2023-12-15T02:14:44.000000Z',
            updated_at: '2023-12-15T02:14:44.000000Z',
            media: [],
            items: [
              {
                id: 21,
                name: 'ကြက်ကြော် (အစပ်)',
                description: 'Item Six Description',
                background_image_url: 'https://qrmenu.sirv.com/Images/qr-menu/menus/menu-19.jpeg',
                old_price: 10,
                price: 30,
                currency: 'MMK',
                current_price: 30,
                quantity: 1,
                is_active: 1,
                menu_id: 9,
                created_at: '2023-12-15T02:14:44.000000Z',
                updated_at: '2023-12-15T02:14:44.000000Z',
                media: [],
              },
              {
                id: 22,
                name: 'ကြက်ကြော် (အချို)',
                description: 'Item Six Description',
                background_image_url: 'https://qrmenu.sirv.com/Images/qr-menu/menus/menu-20.jpeg',
                old_price: 10,
                price: 30,
                currency: 'MMK',
                current_price: 30,
                quantity: 1,
                is_active: 1,
                menu_id: 9,
                created_at: '2023-12-15T02:14:44.000000Z',
                updated_at: '2023-12-15T02:14:44.000000Z',
                media: [],
              },
            ],
          },
          {
            id: 10,
            name: 'ရှမ်းခေါက်ဆွဲ',
            description: 'Menu Eight Description',
            background_image_url:
              'https://qrmenu.sirv.com/Images/qr-menu/categories/category-eight.jpeg',
            is_active: 1,
            category_id: 6,
            created_at: '2023-12-15T02:14:44.000000Z',
            updated_at: '2023-12-15T02:14:44.000000Z',
            media: [],
            items: [],
          },
        ],
      },
      {
        id: 9,
        name: 'ညစာ',
        description: 'Category One Description',
        placement: 5,
        shop_id: 3,
        created_at: '2023-12-15T02:14:44.000000Z',
        updated_at: '2023-12-15T02:14:44.000000Z',
        media: {
          id: 7,
          file_name: 'picture7.png',
          file_url: 'picture7.png',
          file_type: 'picture',
          file_size: 1,
          created_at: '2023-12-15T02:14:44.000000Z',
          updated_at: '2023-12-15T02:14:44.000000Z',
        },
        menus: [],
      },
    ],
  },
  selected_category: {
    id: 6,
    name: 'ဖျော်ရည်',
    description: 'Category One Description',
    background_image_url: 'https://qrmenu.sirv.com/Images/qr-menu/categories/category-seven.jpeg',
    placement: 4,
    shop_id: 3,
    created_at: '2023-12-15T02:14:44.000000Z',
    updated_at: '2023-12-15T02:14:44.000000Z',
    media: {
      id: 7,
      file_name: 'picture7.png',
      file_url: 'picture7.png',
      file_type: 'picture',
      file_size: 1,
      created_at: '2023-12-15T02:14:44.000000Z',
      updated_at: '2023-12-15T02:14:44.000000Z',
    },
    menus: [
      {
        id: 9,
        name: 'ဆီချက်',
        description: 'Menu Seven Description',
        background_image_url:
          'https://qrmenu.sirv.com/Images/qr-menu/categories/category-seven.jpeg',
        is_active: 1,
        category_id: 6,
        created_at: '2023-12-15T02:14:44.000000Z',
        updated_at: '2023-12-15T02:14:44.000000Z',
        media: [],
        items: [
          {
            id: 21,
            name: 'ကြက်ကြော် (အစပ်)',
            description: 'Item Six Description',
            background_image_url: 'https://qrmenu.sirv.com/Images/qr-menu/menus/menu-19.jpeg',
            old_price: 10,
            price: 30,
            currency: 'MMK',
            current_price: 30,
            quantity: 1,
            is_active: 1,
            menu_id: 9,
            created_at: '2023-12-15T02:14:44.000000Z',
            updated_at: '2023-12-15T02:14:44.000000Z',
            media: [],
          },
          {
            id: 22,
            name: 'ကြက်ကြော် (အချို)',
            description: 'Item Six Description',
            background_image_url: 'https://qrmenu.sirv.com/Images/qr-menu/menus/menu-20.jpeg',
            old_price: 10,
            price: 30,
            currency: 'MMK',
            current_price: 30,
            quantity: 1,
            is_active: 1,
            menu_id: 9,
            created_at: '2023-12-15T02:14:44.000000Z',
            updated_at: '2023-12-15T02:14:44.000000Z',
            media: [],
          },
        ],
      },
      {
        id: 10,
        name: 'ရှမ်းခေါက်ဆွဲ',
        description: 'Menu Eight Description',
        background_image_url:
          'https://qrmenu.sirv.com/Images/qr-menu/categories/category-eight.jpeg',
        is_active: 1,
        category_id: 6,
        created_at: '2023-12-15T02:14:44.000000Z',
        updated_at: '2023-12-15T02:14:44.000000Z',
        media: [],
        items: [],
      },
    ],
  },
  selected_menu: {
    id: 9,
    name: 'ဆီချက်',
    description: 'Menu Seven Description',
    background_image_url: 'https://qrmenu.sirv.com/Images/qr-menu/categories/category-seven.jpeg',
    is_active: 1,
    category_id: 6,
    created_at: '2023-12-15T02:14:44.000000Z',
    updated_at: '2023-12-15T02:14:44.000000Z',
    media: [],
    items: [
      {
        id: 21,
        name: 'ကြက်ကြော် (အစပ်)',
        description: 'Item Six Description',
        background_image_url: 'https://qrmenu.sirv.com/Images/qr-menu/menus/menu-19.jpeg',
        old_price: 10,
        price: 30,
        currency: 'MMK',
        current_price: 30,
        quantity: 1,
        is_active: 1,
        menu_id: 9,
        created_at: '2023-12-15T02:14:44.000000Z',
        updated_at: '2023-12-15T02:14:44.000000Z',
        media: [],
      },
      {
        id: 22,
        name: 'ကြက်ကြော် (အချို)',
        description: 'Item Six Description',
        background_image_url: 'https://qrmenu.sirv.com/Images/qr-menu/menus/menu-20.jpeg',
        old_price: 10,
        price: 30,
        currency: 'MMK',
        current_price: 30,
        quantity: 1,
        is_active: 1,
        menu_id: 9,
        created_at: '2023-12-15T02:14:44.000000Z',
        updated_at: '2023-12-15T02:14:44.000000Z',
        media: [],
      },
    ],
  },
  selected_item: {
    id: 21,
    name: 'ကြက်ကြော် (အစပ်)',
    description: 'Item Six Description',
    background_image_url: 'https://qrmenu.sirv.com/Images/qr-menu/menus/menu-19.jpeg',
    old_price: 10,
    price: 30,
    currency: 'MMK',
    current_price: 30,
    quantity: 1,
    is_active: 1,
    menu_id: 9,
    created_at: '2023-12-15T02:14:44.000000Z',
    updated_at: '2023-12-15T02:14:44.000000Z',
    media: [],
  },

  // supplimentry state
  shop_error: null,
  shop_errors: {},
  shops_select_status: 'idle', // idle, loading, error , succeeded
  shop_store_status: 'idle',
  shop_update_status: 'idle',
  shop_delete_status: 'idle',

  category_error: null,
  category_errors: {},
  categories_select_status: 'idle', // လက်ရှိ မလိုသေး။ api သီးသန့်ခေါ်မှာ မဟုတ်ပဲ local state ကိုပဲ သုံးမှာ။
  category_store_status: 'idle',
  category_update_status: 'idle',
  category_delete_status: 'idle',

  menu_error: null,
  menu_errors: {},
  menus_select_status: 'idle', // လက်ရှိ မလိုသေး။ api သီးသန့်ခေါ်မှာ မဟုတ်ပဲ local state ကိုပဲ သုံးမှာ။
  menu_store_status: 'idle',
  menu_update_status: 'idle',
  menu_delete_status: 'idle',

  item_error: null,
  item_errors: {},
  items_select_status: 'idle', // လက်ရှိ မလိုသေး။ api သီးသန့်ခေါ်မှာ မဟုတ်ပဲ local state ကိုပဲ သုံးမှာ။
  item_store_status: 'idle',
  item_update_status: 'idle',
  item_delete_status: 'idle',
};

// Thunks
// သက်ဆိုင်ရာ ဖိုင်တွေက ယူသုံးမယ်။

// Slice
export const mainSlice = createSlice({
  name: 'main', // ဒီနာမည်က ဘယ်မှာ ယူသုံးမှာလဲ? မေ့သွားပြီ။
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // ဒါကတော့ ရှည်လှတဲ့ Reducer List ဖြစ်တော့မယ်။
    // initial state မှာ ထိမ်းထားတဲ့ data တစ်ခုချင်းစီကို CRUD လုပ်နိုင်တဲ့ function တွေ ရေးထားရမှာ။
    // မလိုသေးရင် မရေးပဲ ထားထားမလား? :D
    // Local State တွေကို ဘယ်လို ပြင်ချင်လဲ?

    // shops
    // set detail
    // CRUD လိုသလား? အထူးသဖြင့် UI ကနေ။

    // Page တစ်ခု ကူးဖို့လိုတဲ့အချိန် Shop Card ကို နှိပ်လိုက်ရင် သုံးမယ်။
    // Selected Shop အတွက် Set နဲ့ Update

    // shop id ပဲ ပေးစရာလိုတာ။
    // ဒါကို ဘယ်က တာဝန်ယူမှာလဲ? shop detail page ကလား?
    // shop detail page ကို လာချင်တဲ့ page တွေကလား?

    setSelectedShop: (state, action) => {
      // all we need is shop id
      for (let i = 0; i < state.shops.length; i++) {
        if (state.shops[i].id === action.payload) {
          state.shops[i].selected = true;
          state.selected_shop = JSON.parse(JSON.stringify(state.shops[i]));
        } else {
          state.shops[i].selected = false;
        }
      }
    },
    // update လုပ်ဖို့ လိုလား? field နဲ့ value တစ်ခုချင်းစီကို update လုပ်မယ်။
    // edit page မှာ သုံးဖို့လား?
    // မလိုသေးရင် မရေးသေးတာ ကောင်းမယ်။
    // အခု လိုတာက Selectors တွေပဲ။
    updateSelectedShop: (state, action) => {
      const { name, value } = action.payload;
      state.selected_shop[name] = value;
    },
    // Shop Error (၂)​ မျိုး
    setShopErrors: (state, action) => {
      state.shop_errors = action.payload;
    },
    setShopError: (state, action) => {
      state.shop_error = action.payload;
    },
    //  Shop CRUD status (4) မျိုး
    setShopSelectStatus: (state, action) => {
      state.shops_select_status = action.payload;
    },
    setShopStoreStatus: (state, action) => {
      state.shop_store_status = action.payload;
    },
    setShopUpdateStatus: (state, action) => {
      state.shop_update_status = action.payload;
    },
    setShopDeleteStatus: (state, action) => {
      state.shop_delete_status = action.payload;
    },

    // ဘယ်တုန်းကမှ UI က မလိုတဲ့ ကိစ္စတွေ
    // ဖယ်ထုတ်လိုက်တော့

    // အခု Category အတွက် သွားလိုက်ကြရအောင်
    // Selected Data အတွက် Set နဲ့ Update
    setSelectedCategory: (state, action) => {
      state.selected_category = action.payload;
    },
    updateSelectedCategory: (state, action) => {
      const { name, value } = action.payload;
      state.selected_category[name] = value;
    },
    // Selected Category အတွက် Error (2) မျိုး SET
    setCategoryErrors: (state, action) => {
      state.category_errors = action.payload;
    },
    setCategoryError: (state, action) => {
      state.category_error = action.payload;
    },
    //  Selected Category CRUD status (4) မျိုး
    setCategoryStoreStatus: (state, action) => {
      state.category_store_status = action.payload;
    },
    setCategoryUpdateStatus: (state, action) => {
      state.category_update_status = action.payload;
    },
    setCategoryDeleteStatus: (state, action) => {
      state.category_delete_status = action.payload;
    },

    // အခု Menu အတွက် သွားလိုက်ကြရအောင်
    // Selected Menu အတွက် Set နဲ့ Update
    setSelectedMenu: (state, action) => {
      state.selected_menu = action.payload;
    },
    updateSelectedMenu: (state, action) => {
      const { name, value } = action.payload;
      state.selected_menu[name] = value;
    },
    // Selected Menu အတွက် Error (2) မျိုး SET
    setMenuErrors: (state, action) => {
      state.menu_errors = action.payload;
    },
    setMenuError: (state, action) => {
      state.menu_error = action.payload;
    },
    //  Selected Menu CRUD status (4) မျိုး
    setMenuStoreStatus: (state, action) => {
      state.menu_store_status = action.payload;
    },
    setMenuUpdateStatus: (state, action) => {
      state.menu_update_status = action.payload;
    },
    setMenuDeleteStatus: (state, action) => {
      state.menu_delete_status = action.payload;
    },

    // အခု Item အတွက် သွားလိုက်ကြရအောင်
    // Selected Item အတွက် Set နဲ့ Update

    // ဒီ အုပ်စုကို သိးသန့်ခွဲထုတ်ထားလို့ရမလား
    // ဒါမျိုးပေါ့ TODO: need to seprate and import this section
    // ကျန်တဲ့အပိုင်းတွေလည်း ထိုးနည်းလည်းကောင်း။
    // state တစ်ခုပဲ central ထားပြီး ကျန်တာတွ ခွဲထားတာမျိုး
    // ...itemSlice.reducers
    setSelectedItem: (state, action) => {
      state.selected_item = action.payload;
    },
    updateSelectedItem: (state, action) => {
      const { name, value } = action.payload;
      state.selected_item[name] = value;
    },
    // Selected Menu အတွက် Error (2) မျိုး SET
    setItemErrors: (state, action) => {
      state.item_errors = action.payload;
    },
    setItemError: (state, action) => {
      state.item_error = action.payload;
    },
    //  Selected Menu CRUD status (4) မျိုး
    setItemStoreStatus: (state, action) => {
      state.item_store_status = action.payload;
    },
    setItemUpdateStatus: (state, action) => {
      state.item_update_status = action.payload;
    },
    setItemDeleteStatus: (state, action) => {
      state.item_delete_status = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // API တစ် call ကို case (၃) ခုနဲ့ ပြင်ရမယ်။
      .addCase(selectShopListAsyncThunk.pending, (state) => {
        state.shops_select_status = 'loading';
      })
      .addCase(selectShopListAsyncThunk.fulfilled, (state, action) => {
        state.shops_select_status = 'succeeded';
        state.shops = action.payload;
      })
      .addCase(selectShopListAsyncThunk.rejected, (state, action) => {
        state.shops_select_status = 'error';
        state.shop_error = action.error.message;
      })

      // store shop api
      .addCase(storeShopAsyncThunk.pending, (state) => {
        state.shop_store_status = 'loading';
      })
      .addCase(storeShopAsyncThunk.fulfilled, (state, action) => {
        state.shop_store_status = 'succeeded';
        state.shops.push(action.payload);
      })
      .addCase(storeShopAsyncThunk.rejected, (state, action) => {
        // handle multiple errors for remote validation
        state.shop_store_status = 'error';
        state.shop_error = action.error.message; // Store the error message
        try {
          state.shop_errors = JSON.parse(action.error.message); // Store the error message
        } catch (error) {
          state.shop_errors = {};
        }
      })

      // update shop api
      .addCase(updateShopAsyncThunk.pending, (state) => {
        state.shop_update_status = 'loading';
      })
      .addCase(updateShopAsyncThunk.fulfilled, (state, action) => {
        state.shop_update_status = 'succeeded';
        // find and update
        for (let i = 0; i < state.shops.length; i++) {
          if (state.shops[i].id == action.payload.id) {
            state.shops[i] = action.payload;
          }
        }
      })
      .addCase(updateShopAsyncThunk.rejected, (state, action) => {
        // handle multiple errors for remote validation
        state.shop_update_status = 'error';
        state.shop_error = action.error.message; // Store the error message
        try {
          state.shop_errors = JSON.parse(action.error.message); // Store the error message
        } catch (error) {
          state.shop_errors = {};
        }
      })

      // delete shop api
      .addCase(deleteShopAsyncThunk.pending, (state) => {
        state.shop_delete_status = 'loading';
      })
      .addCase(deleteShopAsyncThunk.fulfilled, (state, action) => {
        state.shop_delete_status = 'succeeded';
        // find and delete
        // test function ထည့်ပေးပြီး test passed တဲ့ item တွေချည်းပဲ array တစ်ခု ပြင်ပေးတာ။
        // alert("action.payload.id");
        // alert(action.payload.id);
        state.shops = state.shops.filter((shop) => shop.id !== action.payload.id);
      })
      .addCase(deleteShopAsyncThunk.rejected, (state, action) => {
        // handle multiple errors for remote validation
        state.shop_delete_status = 'error';
        state.shop_error = action.error.message; // Store the error message
        try {
          state.shop_errors = JSON.parse(action.error.message); // Store the error message
        } catch (error) {
          state.shop_errors = {};
        }
      })

      // store category

      // store category api
      .addCase(storeCategoryAsyncThunk.pending, (state) => {
        state.category_store_status = 'loading';
      })
      .addCase(storeCategoryAsyncThunk.fulfilled, (state, action) => {
        state.category_store_status = 'succeeded';
        let inserted_category = action.payload;
        // state.categories.push(action.payload);

        // လက်ရှိ Selected Shop နဲ့ Shop List မှာ ပြင်ရမယ်။
        for (let i = 0; i < state.shops.length; i++) {
          if (state.shops[i].id == inserted_category.shop_id) {
            state.shops[i].categories.push(inserted_category);
          }
        }
        // selected shop
        if (state.selected_shop.id == inserted_category.shop_id) {
          state.selected_shop.categories.push(inserted_category);
        }
        // insert ဖြစ်သွားတဲ့ဟာကို selected category မှာလည်း သိမ်းထားပေးမယ်။
        state.selected_category = action.payload;
      })
      .addCase(storeCategoryAsyncThunk.rejected, (state, action) => {
        state.category_store_status = 'error';
        // နှစ်ခုတည်းက တစ်ခု ယူရမှာ ။
        try {
          // try multiple errors
          state.category_errors = JSON.parse(action.error.message); // Store the error message
          const firstKey = Object.keys(JSON.parse(action.error.message))[0];
          const firstItem = JSON.parse(action.error.message)[firstKey][0];
          state.category_error = firstItem;
        } catch (err) {
          // error message
          state.category_error = action.error.message; // Store the error message
        }
      })

      // update category api
      .addCase(updateCategoryAsyncThunk.pending, (state) => {
        state.category_update_status = 'loading';
      })
      .addCase(updateCategoryAsyncThunk.fulfilled, (state, action) => {
        state.category_update_status = 'succeeded';
        let updated_category = action.payload;
        // main repo
        for (let i = 0; i < state.shops.length; i++) {
          if (state.shops[i].id == updated_category.shop_id) {
            for (let j = 0; j < state.shops[i].categories.length; j++) {
              if (state.shops[i].categories[j].id == updated_category.id) {
                state.shops[i].categories[j] = {
                  ...state.shops[i].categories[j],
                  ...updated_category,
                };
              }
            }
          }
        }
        // selected shop
        if (state.selected_shop.id == updated_category.shop_id) {
          for (let j = 0; j < state.selected_shop.categories.length; j++) {
            if (state.selected_shop.categories[j].id == updated_category.id) {
              state.selected_shop.categories[j] = {
                ...state.selected_shop.categories[j],
                ...updated_category,
              };
            }
          }
        }
      })
      .addCase(updateCategoryAsyncThunk.rejected, (state, action) => {
        state.category_update_status = 'error';
        // error နှစ်ခုတည်းက တစ်ခု ယူရမှာ ။
        try {
          // try multiple errors
          state.category_errors = JSON.parse(action.error.message); // Store the error message
          const firstKey = Object.keys(JSON.parse(action.error.message))[0];
          const firstItem = JSON.parse(action.error.message)[firstKey][0];
          state.category_error = firstItem;
        } catch (err) {
          // error message
          state.category_error = action.error.message; // Store the error message
        }
      })

      // delete category api
      .addCase(deleteCategoryAsyncThunk.pending, (state) => {
        state.category_delete_status = 'loading';
      })
      .addCase(deleteCategoryAsyncThunk.fulfilled, (state, action) => {
        state.category_delete_status = 'succeeded';
        // state.shops = state.shops.filter(shop => shop.id !== action.payload.id);
        let updated_category = action.payload;
        // main repo
        for (let i = 0; i < state.shops.length; i++) {
          if (state.shops[i].id == updated_category.shop_id) {
            state.shops[i].categories = state.shops[i].categories.filter(
              (category) => category.id !== action.payload.id
            );
          }
        }
        // selected shop
        if (state.selected_shop.id === updated_category.shop_id) {
          state.selected_shop.categories = state.selected_shop.categories.filter(
            (category) => category.id !== action.payload.id
          );
        }
      })
      .addCase(deleteCategoryAsyncThunk.rejected, (state, action) => {
        // handle multiple errors for remote validation
        state.category_delete_status = 'error';
        // နှစ်ခုတည်းက တစ်ခု ယူရမှာ ။
        try {
          // try multiple errors
          state.category_errors = JSON.parse(action.error.message); // Store the error message
          const firstKey = Object.keys(JSON.parse(action.error.message))[0];
          const firstItem = JSON.parse(action.error.message)[firstKey][0];
          state.category_error = firstItem;
        } catch (err) {
          // error message
          state.category_error = action.error.message; // Store the error message
        }
      })

      // store menu api
      .addCase(storeMenuAsyncThunk.pending, (state) => {
        state.menu_store_status = 'loading';
      })
      .addCase(storeMenuAsyncThunk.fulfilled, (state, action) => {
        state.menu_store_status = 'succeeded';
        let inserted_menu = action.payload;
        // Data (၃) ခုပြင်မယ်။

        state.selected_menu = inserted_menu;
        // ဒါကို ဘယ်မှာ တိုးမှာလဲ
        // selected category မှာ တိုးမယ်။
        // selected category ပြီးရင် selected shop မှာလည်း တိုးရမယ်။
        // ပြီးရင် Fianl State ဖြစ်တဲ့ shop list မှာလည်း တိုးမယ်။

        // selected category
        if (state.selected_category.id == inserted_menu.category_id) {
          // selected category ကို ပြင်မယ်။
          state.selected_category.menus.push(inserted_menu);

          // selected shop
          if (state.selected_shop.id == state.selected_category.shop_id) {
            // အမှန်ကတော့ တူရမှာပဲ။
            // သူမှာပါတဲ့ categories တွေထဲကမှ selected_category ကို update လုပ်မယ်။
            for (let i = 0; i < state.selected_shop.categories.length; i++) {
              // selected shop ထဲက category ကို ပြင်မယ်။
              if (state.selected_shop.categories[i].id == state.selected_category.id) {
                state.selected_shop.categories[i] = state.selected_category;
              }
            }

            // main repo ထဲက ဟာပါ ပြင်ရမှာ
            // တစ်ကြောင်းထဲနဲ့ ပြီးမယ့် ကိစ္စတွေ။ map တို့ဘာတို့ ကျွမ်းကျွမ်းကျင်ကျင်မသုံးတက်တော့ ခက်ရော
            // အခု လို အရှည်ကြီးတွေ Loop ပတ်ပြီး ရည်ရွယ်ချက်ကို နားလည်ဖို့ ခက်အောင် ရေးနေရတယ်။
            for (let i = 0; i < state.shops.length; i++) {
              // selected shop ထဲက category ကို ပြင်မယ်။
              if (state.shops[i].id == state.selected_shop.id) {
                state.shops[i] = state.selected_shop;
              }
            }
          }
        }
        // မညီဘူးဆိုရင်တော့ ဆက်လုပ်စရာ အကြောင်းမရှိ။
        // မဟုတ်ရင် အကြာကြီးရှာပြီးလုပ်ရတော့မှာ။
        // တစ်ခုရှိတာက api ဘက်က ဘာသာပြန်ပေးထားတယ်ဆိုရင်တော့ လိုက်ရှာရတာ လွယ်မယ်။
        // inserted_menu.category ဒီလောက် ပေးရင်ကို ရပြီ။
        // ဒါက ပြဿနာ မရှိဘူး API ဘက်က ပေးလိမ့်မယ်။
      })
      .addCase(storeMenuAsyncThunk.rejected, (state, action) => {
        state.menu_store_status = 'error';
        // နှစ်ခုတည်းက တစ်ခု ယူရမှာ ။
        try {
          // try multiple errors
          state.menu_errors = JSON.parse(action.error.message); // Store the error message
          const firstKey = Object.keys(JSON.parse(action.error.message))[0];
          const firstItem = JSON.parse(action.error.message)[firstKey][0];
          state.menu_error = firstItem;
        } catch (err) {
          // error message
          state.menu_error = action.error.message; // Store the error message
        }
      })

      // update menu api
      .addCase(updateMenuAsyncThunk.pending, (state) => {
        state.menu_update_status = 'loading';
      })
      .addCase(updateMenuAsyncThunk.fulfilled, (state, action) => {
        state.menu_update_status = 'succeeded';
        let updated_menu = action.payload;
        // Central Repo မှာ စ ဖြတ် မယ်။
        state.shops = state.shops.map(function (shop) {
          shop.categories = shop.categories.map(function (category) {
            // category
            category.menus = category.menus.map(function (menu) {
              // check and update
              if (menu.id === updated_menu.id) menu = updated_menu;
              return menu;
            });
            return category;
          });
          return shop;
        });
        // selected shop ဘယ်လိုပြင်မလဲ?
        // find
        state.selected_shop = state.shops.find((shop) => shop.id === state.selected_shop.id);
        // selected category
        state.selected_category = state.shops
          .find((shop) => shop.id === state.selected_shop.id)
          .categories.find((category) => category.id === state.selected_category.id);
        // selected menu
        state.selected_menu = updated_menu;
      })
      .addCase(updateMenuAsyncThunk.rejected, (state, action) => {
        state.menu_update_status = 'error';
        // နှစ်ခုတည်းက တစ်ခု ယူရမှာ ။
        try {
          // try multiple errors
          state.menu_errors = JSON.parse(action.error.message); // Store the error message
          const firstKey = Object.keys(JSON.parse(action.error.message))[0];
          const firstItem = JSON.parse(action.error.message)[firstKey][0];
          state.menu_error = firstItem;
        } catch (err) {
          // error message
          state.menu_error = action.error.message; // Store the error message
        }
      })

      // delete menu api
      .addCase(deleteMenuAsyncThunk.pending, (state) => {
        state.menu_delete_status = 'loading';
      })
      .addCase(deleteMenuAsyncThunk.fulfilled, (state, action) => {
        state.menu_delete_status = 'succeeded';
        let deleted_menu = action.payload;
        // Central Repo မှာ စ ဖြတ် မယ်။
        state.shops = state.shops.map(function (shop) {
          shop.categories = shop.categories.map(function (category) {
            // category
            category.menus = category.menus.filter(function (menu) {
              // return true or false to include in new array
              return menu.id !== deleted_menu.id;
            });
            return category;
          });
          return shop;
        });
        // selected shop ဘယ်လိုပြင်မလဲ?
        // find
        state.selected_shop = state.shops.find((shop) => shop.id === state.selected_shop.id);
        // selected category
        state.selected_category = state.shops
          .find((shop) => shop.id === state.selected_shop.id)
          .categories.find((category) => category.id === state.selected_category.id);
        // selected menu
        state.selected_menu = deleted_menu;
      })
      .addCase(deleteMenuAsyncThunk.rejected, (state, action) => {
        state.menu_delete_status = 'error';
        // နှစ်ခုတည်းက တစ်ခု ယူရမှာ ။
        try {
          // try multiple errors
          state.menu_errors = JSON.parse(action.error.message); // Store the error message
          const firstKey = Object.keys(JSON.parse(action.error.message))[0];
          const firstItem = JSON.parse(action.error.message)[firstKey][0];
          state.menu_error = firstItem;
        } catch (err) {
          // error message
          state.menu_error = action.error.message; // Store the error message
        }
      })

      // store item api
      .addCase(storeItemAsyncThunk.pending, (state) => {
        state.item_store_status = 'loading';
      })
      .addCase(storeItemAsyncThunk.fulfilled, (state, action) => {
        state.item_store_status = 'succeeded';
        let inserted_item = action.payload;
        // Central Repo မှာ စ ဖြတ် မယ်။
        state.shops = state.shops.map(function (shop) {
          // menu level မှာပဲ​ if နဲ့ စစ်မလား?
          // level တိုင်းမှာ စစ်မလား
          // ကုတ်ရေးတာ ရှင်းတာကတော့ menu level မှာ စစ်လိုက်တာ အတော်ရှင်းတယ်။
          // shop တိုင်းကို Loop ပတ်စရာ လိုမလား?
          // မလိုဘူး။

          /*
                    if(shop.id == inserted_item.menu.category.shop.id){
                        // ဒါမှ ပတ်မှာ
                        shop.categories = shop.categories.map(function (category) {
                            // category တိုင်း ပတ်စရာ လိုလား? 
                            if(category.id == inserted_item.menu.category_id){
                                // ဒီ category ကိုပဲ ပတ်မယ်။
                                category.menus = category.menus.map(function (menu) {
                                    // menu တိုင်း ပတ်စရာ လိုလား?
                                    if(menu.id == inserted_item.menu_id){
                                        // လုပ်ချင်တာ လုပ်ခဲ့နိုင်ပြီ.
                                        // repo ထဲက insert လုပ်ရမယ့် နေရာကို တွေ့ပြီ။
                                        menu.items.add(inserted_item);
                                    }
                                    return menu;
                                });
                            }
                            return category;
                        })
                    }
                    return shop;
                    */
          shop.categories = shop.categories.map(function (category) {
            if (category.id == inserted_item.category_id) category.menus.push(category);
            category.menus = category.menus.map(function (menu) {
              // check and insert as first item
              if (menu.id == inserted_item.menu_id) menu.items.unshift(inserted_item);
              return menu;
            });
            return category;
          });
          return shop;
        });
        // selected shop ဘယ်လိုပြင်မလဲ?
        // တကယ့် Repo ထဲကနေ ရှာတော့မယ်။
        // အပေါ်က Loop တွေမှာ ရှာခဲ့လဲ​ရပေမယ့် ကုတ်ရှင်းအောင်လုပ်ဖို့လည်း လိုသေးလို့ အောက်က Approach အတိုင်းပဲ သွားမယ်။

        // find
        state.selected_shop = state.shops.find((shop) => shop.id === state.selected_shop.id);
        // selected category
        // ဒီမှာ မတွေ့တော့လို့ အောက်က menu မှာ ပြဿနာ တက်တာလား?
        state.selected_category = state.shops
          .find((shop) => shop.id === state.selected_shop.id)
          .categories.find((category) => category.id === state.selected_category.id);
        // selected menu
        // item insert မှာ selected category မှာ id မရှိတော့ဘူး။ ဘာလို့လဲ?
        state.selected_menu = state.shops
          .find((shop) => shop.id === state.selected_shop.id)
          .categories.find((category) => category.id === state.selected_category.id)
          .menus.find((menu) => menu.id === state.selected_menu.id);
        // selected item
        state.selected_item = inserted_item;
      })
      .addCase(storeItemAsyncThunk.rejected, (state, action) => {
        state.item_store_status = 'error';
        // နှစ်ခုတည်းက တစ်ခု ယူရမှာ ။
        try {
          // try multiple errors
          state.item_errors = JSON.parse(action.error.message); // Store the error message
          const firstKey = Object.keys(JSON.parse(action.error.message))[0];
          const firstItem = JSON.parse(action.error.message)[firstKey][0];
          state.item_error = firstItem;
        } catch (err) {
          // error message
          state.item_error = action.error.message; // Store the error message
        }
      })

      // update item api
      .addCase(updateItemAsyncThunk.pending, (state) => {
        state.item_update_status = 'loading';
      })
      .addCase(updateItemAsyncThunk.fulfilled, (state, action) => {
        state.item_update_status = 'succeeded';
        // ဒီမှာ ကြည့်စရာ key point က (၃) ခုပဲရှိတယ်။
        // api response payload

        // central repo မှာ သက်ဆိုင်ရာ item ကိုရှာပြီး assign လုပ်

        // selected item ကို ပြင်။

        let updated_item = action.payload;
        // Central Repo မှာ စ ဖြတ် မယ်။
        state.shops = state.shops.map(function (shop) {
          shop.categories = shop.categories.map(function (category) {
            category.menus = category.menus.map(function (menu) {
              menu.items = menu.items.map(function (item) {
                if (item.id == updated_item.id) item = updated_item;
                return item;
              });
              return menu;
            });
            return category;
          });
          return shop;
        });
        // selected shop ဘယ်လိုပြင်မလဲ?
        // find
        state.selected_shop = state.shops.find((shop) => shop.id == state.selected_shop.id);
        // selected category
        state.selected_category = state.shops
          .find((shop) => shop.id == state.selected_shop.id)
          .categories.find((category) => category.id == state.selected_category.id);
        // selected menu
        state.selected_menu = state.shops
          .find((shop) => shop.id == state.selected_shop.id)
          .categories.find((category) => category.id == state.selected_category.id)
          .menus.find((menu) => menu.id == state.selected_menu.id);
        // selected item
        state.selected_item = updated_item;
      })
      .addCase(updateItemAsyncThunk.rejected, (state, action) => {
        state.item_update_status = 'error';
        // နှစ်ခုတည်းက တစ်ခု ယူရမှာ ။
        try {
          // try multiple errors
          state.item_errors = JSON.parse(action.error.message); // Store the error message
          const firstKey = Object.keys(JSON.parse(action.error.message))[0];
          const firstItem = JSON.parse(action.error.message)[firstKey][0];
          state.item_error = firstItem;
        } catch (err) {
          // error message
          state.item_error = action.error.message; // Store the error message
        }
      })

      // delete item api
      .addCase(deleteItemAsyncThunk.pending, (state) => {
        state.item_delete_status = 'loading';
      })
      .addCase(deleteItemAsyncThunk.fulfilled, (state, action) => {
        state.item_delete_status = 'succeeded';
        let deleted_item = action.payload;
        state.shops = state.shops.map(function (shop) {
          shop.categories = shop.categories.map(function (category) {
            category.menus = category.menus.map(function (menu) {
              // ဖျတ်လိုက်တဲ့ item ကို ဖယ်ထုတ်
              // filter က bool ပြန်ပေးရမှာ။ new array မှာ ပါသင့် မပါသင့်ကို
              menu.items = menu.items.filter((item) => item.id != deleted_item.id);
              return menu;
            });
            return category;
          });
          return shop;
        });
        // selected shop ဘယ်လိုပြင်မလဲ?
        // find
        state.selected_shop = state.shops.find((shop) => shop.id == state.selected_shop.id);
        // selected category
        state.selected_category = state.shops
          .find((shop) => shop.id == state.selected_shop.id)
          .categories.find((category) => category.id == state.selected_category.id);
        // selected menu
        state.selected_menu = state.shops
          .find((shop) => shop.id == state.selected_shop.id)
          .categories.find((category) => category.id == state.selected_category.id)
          .menus.find((menu) => menu.id == state.selected_menu.id);
        // selected item
        state.selected_item = deleted_item;
      })
      .addCase(deleteItemAsyncThunk.rejected, (state, action) => {
        state.item_delete_status = 'error';
        // နှစ်ခုတည်းက တစ်ခု ယူရမှာ ။
        try {
          // try multiple errors
          state.item_errors = JSON.parse(action.error.message); // Store the error message
          const firstKey = Object.keys(JSON.parse(action.error.message))[0];
          const firstItem = JSON.parse(action.error.message)[firstKey][0];
          state.item_error = firstItem;
        } catch (err) {
          // error message
          state.item_error = action.error.message; // Store the error message
        }
      });
  },
});

// actions
export const {
  // actions about shop
  setSelectedShop,
  updateSelectedShop,
  setShopErrors,
  setShopError,
  setShopSelectStatus,
  setShopStoreStatus,
  setShopUpdateStatus,
  setShopDeleteStatus,

  // actions about category
  setSelectedCategory,
  updateSelectedCategory,
  setCategoryError,
  setCategoryErrors,
  setCategoryStoreStatus,
  setCategoryUpdateStatus,
  setCategoryDeleteStatus,

  // menu actions
  setSelectedMenu,
  updateSelectedMenu,
  setMenuError,
  setMenuErrors,
  setMenuStoreStatus,
  setMenuUpdateStatus,
  setMenuDeleteStatus,

  // item actions
  setSelectedItem,
  updateSelectedItem,
  setItemError,
  setItemErrors,
  setItemStoreStatus,
  setItemUpdateStatus,
  setItemDeleteStatus,
} = mainSlice.actions;

// selectors
/* Global State / Combined Reducers ကနေ select လုပ်နေတယ်ဆိုတာ သတိကပ်ထားရမယ် */
// Shop Data နှစ်ခု ( List and Detail )
export const selectShopList = (state) => state.main.shops;
export const selectSelectedShop = (state) => state.main.selected_shop;

// current category list

export const selectSelectedCategoryList = (state) => state.main.selected_shop.categories;
// menu list က ဘယ်လိုလုပ်မလဲ?
/*​ လက်ရှိ Item ရဲ့ Menu ရဲ့ category ရဲ့ menu list ကို ပေးရမှာ  */
export const selectSelectedMenuList = (state) =>
  state.main.selected_shop.categories.find(
    (category) => category.id === state.main?.selected_item?.menu?.category_id
  )?.menus;

// export const selectSelectedMenuList = (state) => state.main.selected_shop.categories.find(category => category.id === state.main.selected_item.menu.category_id).menus;

// Status လေးခု ( CRUD Status )
export const selectShopSelectStatus = (state) => state.main.shops_select_status;
export const selectShopStoreStatus = (state) => state.main.shop_store_status;
export const selectShopUpdateStatus = (state) => state.main.shop_update_status;
export const selectShopDeleteStatus = (state) => state.main.shop_delete_status;
// Error နှစ်ခု ( အနည်း နဲ ့ အများ ဖ)
export const selectShopError = (state) => state.main.shop_error;
export const selectShopErrors = (state) => state.main.shop_errors;

// Category Data (၁) ခု ( Detail )
export const selectSelectedCategory = (state) => state.main.selected_category;
// Status (၃) ခု ( C UD Status )
export const selectCategoryStoreStatus = (state) => state.main.category_store_status;
export const selectCategoryUpdateStatus = (state) => state.main.category_update_status;
export const selectCategoryDeleteStatus = (state) => state.main.category_delete_status;
// Error နှစ်ခု ( အနည်း နဲ ့ အများ ဖ)
export const selectCategoryError = (state) => state.main.category_error;
export const selectCategoryErrors = (state) => state.main.category_errors;

// Menu Data (၁) ခု ( Detail )
export const selectSelectedMenu = (state) => state.main.selected_menu;
// Status (၃) ခု ( C UD Status )
export const selectMenuStoreStatus = (state) => state.main.menu_store_status;
export const selectMenuUpdateStatus = (state) => state.main.menu_update_status;
export const selectMenuDeleteStatus = (state) => state.main.menu_delete_status;
// Error နှစ်ခု ( အနည်း နဲ ့ အများ ဖ)
export const selectMenuError = (state) => state.main.menu_error;
export const selectMenuErrors = (state) => state.main.menu_errors;

// Item Data (၁) ခု ( Detail )
export const selectSelectedItem = (state) => state.main.selected_item;
// Status (၃) ခု ( C UD Status )
export const selectItemStoreStatus = (state) => state.main.item_store_status;
export const selectItemUpdateStatus = (state) => state.main.item_update_status;
export const selectItemDeleteStatus = (state) => state.main.item_delete_status;
// Error နှစ်ခု ( အနည်း နဲ ့ အများ ဖ)
export const selectItemError = (state) => state.main.item_error;
export const selectItemErrors = (state) => state.main.item_errors;
// Dashboard Info

export const selectShopCount = (state) => state.main.shops.length;
export const selectCategoryCount = (state) => {
  let categoryCount = 0;
  for (let i = 0; i < state.main.shops.length; i++) {
    for (let j = 0; j < state.main.shops[i].categories.length; j++) {
      categoryCount++;
    }
  }
  return categoryCount;
};
//export const selectCategoryCount = (state) => state.main.shops.map()
//export const selectMenuCount = (state) => state.main.user.dashboard.menu;
export const selectMenuCount = (state) => {
  let menuCount = 0;
  for (let i = 0; i < state.main.shops.length; i++) {
    for (let j = 0; j < state.main.shops[i].categories.length; j++) {
      menuCount += state.main.shops[i].categories[j].menus.length;
    }
  }
  return menuCount;
};
// export const selectItemCount = (state) => state.main.user.dashboard.item;
export const selectItemCount = (state) => {
  let itemCount = 0;
  for (let i = 0; i < state.main.shops.length; i++) {
    for (let j = 0; j < state.main.shops[i].categories.length; j++) {
      for (let k = 0; k < state.main.shops[i].categories[j].menus.length; k++) {
        itemCount += state.main.shops[i].categories[j].menus[k].items.length;
      }
    }
  }
  return itemCount;
};

// export reducer
export default mainSlice.reducer;
