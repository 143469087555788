// config.js
// internal network
export const  API_HOST = "https://qr.mmsoftware100.com";
//export const  API_HOST = "http://192.168.4.101/qrmenu/public";
//http://192.168.4.101 ိ်ိ dfd
// export const API_HOST = "http://172.20.10.10/qrmenu/public";
export const WAITING_TIME = 0;
// export const API_HOST =  "http://192.168.153.89/qrmenu/public"; //
// export const API_HOST =  "http://localhost/qrmenu/public"; 

// export const API_HOST =  "https://qr.mmsoftware100.com"; //
export const FILE_HOST = API_HOST;
export const API_VERSION = "/api/v1";
export const API_ENDPOINT = API_HOST + API_VERSION;
export const LOGIN_API_ENDPOINT = API_ENDPOINT + "/login";
export const REGISTER_API_ENDPOINT = API_ENDPOINT + "/register";
export const ARTICLE_API_ENDPOINT = API_ENDPOINT + "/articles";
export const VOTE_API_ENDPOINT = API_ENDPOINT + "/votes";
export const SHOP_NAME_API_ENDPOINT = API_ENDPOINT + "/qr-menu";
export const SHOP_API_ENDPOINT = API_ENDPOINT + "/shops";
export const CATEGORY_API_ENDPOINT = API_ENDPOINT + "/categories";
export const MENU_API_ENDPOINT = API_ENDPOINT + "/menus";
export const ITEM_API_ENDPOINT = API_ENDPOINT + "/items";