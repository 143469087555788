import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loginApi, registerApi } from './userAPI';

// ဒါက Initial State
const initialState = {
  user: {
    id: 3,
    name: 'platinum',
    email: 'platinum@email.com',
    email_verified_at: null,
    role_id: 3,
    shops_count: 5,
    active_subscription: {
      id: 5,
      user_id: 3,
      plan_id: 4,
      days_limit: 60,
      start_date: '2023-12-22 02:14:45',
      end_date: '2024-02-20 02:14:45',
      status: 1,
      created_at: '2023-12-15T02:14:45.000000Z',
      updated_at: '2023-12-15T02:14:45.000000Z',
      plan: {
        id: 4,
        name: 'Premium',
        price: '29.99',
        description: 'Premium plan with all features',
        created_at: '2023-12-15T02:14:45.000000Z',
        updated_at: '2023-12-15T02:14:45.000000Z',
      },
    },
    role: {
      id: 3,
      name: 'client',
      created_at: '2023-12-15T02:14:44.000000Z',
      updated_at: '2023-12-15T02:14:44.000000Z',
    },
    // access_token:
    //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYjE5MjA0NmNlZTQ3NGFhYTM2NDlmYmY5YTYzZTJmNDc3ODRkODdiYTRkNDE1MmUxZjJlYTc2ZGY1NWUyOWY5MTU4ZWVkMThhODg4MmU0ZGMiLCJpYXQiOjE3MDQ1NDgxNDAuOTA4NTk2OTkyNDkyNjc1NzgxMjUsIm5iZiI6MTcwNDU0ODE0MC45MDg1OTg4OTk4NDEzMDg1OTM3NSwiZXhwIjoxNzM2MTcwNTQwLjkwNDEzNTk0MjQ1OTEwNjQ0NTMxMjUsInN1YiI6IjMiLCJzY29wZXMiOltdfQ.ffM3OCkM1199uqYs3d-jBtKGU5N-Qo0qjqINZpsmnQ7Io4zRzoT9QWHIDnP6CvEtX64soqKAdeYuQNd2gnm4-AM0h33r57unBBZHGJj16v9YVQrxIDwfZ-IM-iJRxTzpi4oEHeFiZM_tHXwqV8H3hNG0TOzekmS3iD9iHr2FbjtfGKcia2IhC6LHXw7FuLE91qHSgWQIl2cdzBKQJUIUYYsdydH8OcAe3xNyezb0x60Lz5Lok0IzmVtITgvTrOMpDGle_mjHdi0lCq_uGDb9rDr3EMmxBta1YbkFP2We8bUJfgwKTWgySJWHcfXq3CEY1QksRm0qWAcuW4tIZqiTgiSl8RUL2Kgqt0ekj-NUwvW1R5M4VM4-eK319bzV8Hkt3d8FuZjCyAqdLKoAqIXp9ZOzLF_MnhYAIqBB2Y6vpKFEtap1qv41rgabz-5DP6lxnegvhc1JCSgvL8hZPXueDzNDbdBCvuwsZBkXnlYysPIinacZNwN3hJsUxElfbLhFsNGN13GuKfAjvAsCh8ZWBta3eC8iBDQD_j0jR-8vqAnRCIlTpl8E7eOFpFPwYuobH1fHoEJWJz9BLD2JrUjDGpbLtmKDr67WzSjkHL2DYxSYhsW231a0iZgcPZWJqGkEXXRl9O7H8kMznoXBTnTe-kTBc2ROAFChWOixpvIJ2_8',
    dashboard: {
      shop: 5,
      category: 10,
      menu: 21,
      item: 7,
    },
  },
  user_old: {
    id: 1,
    uid: null,
    name: 'admin',
    phone: null,
    city: null,
    age: null,
    gender: null,
    photo_url: 'https://i.ibb.co/tZd24nZ/fotor-ai-2023053095036.png',
    email: 'admin@email.com',
    email_verified_at: null,
    role_id: 2,
    created_at: '2023-09-27T02:37:44.000000Z',
    updated_at: '2023-09-27T02:37:44.000000Z',
    access_token: null, // "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMTMwN2I4YjFjYjMxMzZlM2FkZmM5ODNlM2MzYjI4MzJiZmJkMTIzYTBiY2IyYWJlMWQ1MTM5YzAyNDYzYTM4NGQ0MmYxNzNlYjE0OTA2MWUiLCJpYXQiOjE2OTc5NDg3MjkuOTQxMzY1MDAzNTg1ODE1NDI5Njg3NSwibmJmIjoxNjk3OTQ4NzI5Ljk0MTM2NTk1NzI2MDEzMTgzNTkzNzUsImV4cCI6MTcyOTU3MTEyOS45MzExMjcwNzEzODA2MTUyMzQzNzUsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.C1kW_cNmxp2Rf49ZrlFcgfyJzOomBxRgUBxdH4H9iNZ7Yap7o2TzIR2pvmO7odnFkW90IDE6Uc4ib54LNVpxz339Cnbw1uT_PVS9eEiZ8PYfRE2Zlwnhzt7V0K-RNXu4qaXByiFzCa1BR66GQBPCLsRp0s6CA0yCEkhNRQLt4YoJwJgDkqlo5BG_Byrvs2Izm8C6tRVGZVvqnODEByLWb48BqTfnHw6xtd-08WcrIcOzrLLkxvVe-2Hrre7k9RSzlpT4tJyly3FDKYD8tN9lKNJSqt9hg8k1Lbl3Kh7xwkB2OCOQp8mFrfueMhuR7aB58wUkJg4F28MNZTNIORElKl6ZYXOllwDEuYwsHwZG7JANXopO9ydoHbOWgWLtnaHbF_6TAg-12s4lfqme3hDWT7S66AWRkFcWoo_FNAVO2qIYUxbyqA0O_CAoQQEEJLtir18dgy13uZQsWhj80_uz0M4QcgDObywgw2JEGQMpLBJBOk5PILm_67AH1TgSHLzUqpf_qcudtfSlLRuolG5NHltBX84ljgOtMoUnia0QvZRIaZWIna7U3PRyxNzO_g28XszLQY1rx8qjtABVuFr1jJAZ0g2ujRs4ua_ZFe7oIGYEageSE-ZRt9NH3lV95-fUp1UaLszYScpY1bsWxdccYlHLAPlcSFw_nnVUZRGLtdA",
    user_statistics: [],
  },
  status: 'idle', // idle, loading, error , succeeded
  error: null,
  errors: {}, // for user register form validation errors
};
const initialState2 = JSON.parse(JSON.stringify(initialState));

// Thunks

// loginAsync({email: "tes@em.com", "password" : "pwer"});

export const loginAsync = createAsyncThunk('user/login', async ({ email, password }) => {
  console.log('loginAsync started');
  // ဒီ function စခေါ်တာနဲ့ pending action ကို dispatch လုပ်မယ်။
  // TODO: try catch this future
  // ဒီမှာ မ catch ဘူးဆိုတာကဘာလဲ?
  // exception တက်ခဲ့ရင် error action ကို dispatch လုပ်မှာလား? ဒါလည်း မပြောတက်။
  // အခုလောလောဆယ် UI Component ကနေ Login ဝင်ချင်ရင် လုပ်စရာလိုတာက ဒီ​ Thunk Creator ကို Dispatch လုပ်ရုံပဲ
  // ဒါမျိုး dispatch(loginAsync({email, password}));

  const response = await loginApi({ email, password });
  console.log('loginAsync response');
  console.log(response);
  // The value we return becomes the `fulfilled` action payload
  // return ပြန်လိုက်တာနဲ့ ဒီ Thunk က fulfilled action ကို ထုတ်ပေး (dispatch) မယ်။
  return response.data;
});

export const registerThunk = createAsyncThunk(
  'user/register',
  async ({ name, email, password, confirm_password }) => {
    const response = await registerApi({ name, email, password, confirm_password });
    console.log(response);
    return response.data;
  }
);

// Slice
export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    logout: (state) => {
      state.status = 'idle';
    },
    setErrors: (state, action) => {
      /*
      let action = {
        "payload" : []
      }
      */
      state.errors = action.payload;
      //console.log("userSlice->setErrors");
      //console.log(localErrors);
    },
    setStatusIdle: (state) => {
      state.status = 'idle';
    },
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        console.log('loginAsync.pending');
        state.status = 'loading';
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        console.log('loginAsync.fulfilled');
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        console.log('loginAsync.rejected');
        state.status = 'error';
        state.error = action.error.message; // Store the error message
      })

      // User Registration
      .addCase(registerThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registerThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(registerThunk.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message; // Store the error message
        state.errors = JSON.parse(action.error.message); // Store the error message
        console.log('registerThunk.rejected');
        console.log(action);
      });
  },
});

// actions

export const { logout, setErrors, setStatusIdle, increment, decrement, incrementByAmount } =
  userSlice.actions;

// selectors
export const loginStatus = (state) => state.user.status;
export const loginError = (state) => state.user.error;
export const loggedInUser = (state) => state.user.user;
export const selectLoggedInUser = (state) => state.user.user;
export const selectAccessToken = (state) => state.user.user.access_token;
export const selectUserRegistrationValidationError = (state) => state.user.errors;
// export const selectShopCount = (state) => state.user.user.dashboard.shop;
// export const selectCategoryCount = (state) => state.user.user.dashboard.category;
// export const selectMenuCount = (state) => state.user.user.dashboard.menu;
// export const selectItemCount = (state) => state.user.user.dashboard.item;

// export reducer
// ဒါက combined reducer က ယူသုံးဖို့ ထင်ရဲ့။
// ဒါက Gloal State ထဲက ယူပြရမှာ။
// Combined Reducer ခေါ်မလားပဲ။
export default userSlice.reducer;
