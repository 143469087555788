import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import userReducer from '../features/user/userSlice';
import shopReducer from '../features/shop/shopSlice';
import categoryReducer from '../features/category/categorySlice';
import menuReducer from 'features/menu/menuSlice';
import itemReducer from 'features/item/itemSlice';
import mainReducer from 'features/main/mainSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    shops: shopReducer,
    categories: categoryReducer,
    menus: menuReducer,
    items: itemReducer,
    main: mainReducer
  },
});
