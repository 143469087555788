
import 'template/global.css';

import { useScrollToTop } from 'template/hooks/use-scroll-to-top';

import Router from 'template/routes/sections';
import ThemeProvider from 'template/theme';

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    // <ThemeProvider>
      <Router />
    // </ThemeProvider>
  );
}
