import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { selectCategoryListApi, storeCategoryApi, updateCategoryApi, deleteCategoryApi } from './categoryAPI';

// ဒါက Initial State
const initialState = {
    categories: [],
    status: 'idle', // idle, loading, error , succeeded
    error: null,
    errors: {}, // for user register form validation errors

    // status တွေတော့ ခွဲထားပြီး
    // error နဲ့ errors တွေကိုတော့ တစ်ခုတည်းမျှသုံးနေကြတာမျိူး
    // အစဉ်ပြေမလား?

    store_status: 'idle',
    update_status: 'idle',
    delete_status: 'idle',
    selected_category: {
        "id": 7,
        "name": "this is Menu Name",
        "description": "this is description",
        "placement": 5,
        "shop_id": 4,
        "created_at": "2023-11-20T03:29:26.000000Z",
        "updated_at": "2023-11-20T13:07:24.000000Z",
        "media": null,
        "menus": [
            {
                "id": 10,
                "name": "menu one",
                "description": "this is description",
                "background_image_url": "http://192.168.197.89/qrmenu/public/storage/ZKB3qPSGxIDEMrNCTbePN6pZ6ViAYiEV5YoCySNh.jpg",
                "is_active": 1,
                "category_id": 7,
                "created_at": "2023-11-20T14:09:47.000000Z",
                "updated_at": "2023-11-20T14:09:47.000000Z",
                "media": [],
                "items": []
            },
            {
                "id": 11,
                "name": "menu two",
                "description": "this is description",
                "background_image_url": "http://192.168.197.89/qrmenu/public/storage/ZKB3qPSGxIDEMrNCTbePN6pZ6ViAYiEV5YoCySNh.jpg",
                "is_active": 1,
                "category_id": 7,
                "created_at": "2023-11-20T14:10:05.000000Z",
                "updated_at": "2023-11-20T14:10:05.000000Z",
                "media": [],
                "items": []
            }
        ]
    }
};

// Thunks
export const selectCategoryListAsyncThunk = createAsyncThunk(
    'categories/select',
    async ({ access_token, page }, { getState }) => {
        const state = getState(); // <-- invoke and access state object
        if (state.categories.categories.length > 0 && page == 1) {
            return state.categories.categories;
        }
        const response = await selectCategoryListApi({ access_token, page });
        return response.data;
    }
);
export const storeCategoryAsyncThunk = createAsyncThunk(
    'categories/store',
    async (formData) => {
        const response = await storeCategoryApi(formData);
        return response.data;
    }
);
export const updateCategoryAsyncThunk = createAsyncThunk(
    'categories/update',
    async (formData) => {
        const response = await updateCategoryApi(formData);
        return response.data;
    }
);
export const deleteCategoryAsyncThunk = createAsyncThunk(
    'categories/delete',
    async (formData) => {
        const response = await deleteCategoryApi(formData);
        return response.data;
    }
);

// Slice
export const categorySlice = createSlice({
    name: 'categories',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // data (၈) ခု ရှိရင်၊​ setter က အနည်းဆုံး (၈) ခုလိုမယ်။
        // နာမည်အတိအကျ ပေးထားတာ နောက်ပိုင်း ပေါင်းသုံးတဲ့အခါကြ အဆင်ပြေမယ်။
        // updater လည်း လိုမယ်။ ကွက်ပြီး update လုပ်ချင်တာမျိုးဆိုရင်။

        // variable တွေကို snake_case သုံးပြီး
        // function / methods တွေကို smallCamelCase သုံးမယ်။
        // UI Component တွေကို UpperCamelCase သုံးမယ်။


        // two main data setters
        setCategories: (state, action) => {
            state.categories = action.payload;
        },
        setSelectedCategory: (state, action) => {
            state.selected_category = action.payload;
        },
        // detail data updater
        updateSelectedCategory: (state, action) => {
            const { name, value } = action.payload;
            state.selected_category[name] = value;
        },
        // status updater
        setCategoryStatus: (state, action) => {
            state.status = action.payload;
        },
        setCategoryStoreStatus: (state, action) => {
            state.store_status = action.payload;
        },
        setCategoryUpdateStatus: (state, action) => {
            state.update_status = action.payload;
        },
        setCategoryDeleteStatus: (state, action) => {
            state.delete_status = action.payload;
        },
        // set errors, this is for Clear Errors
        setCategoryErrors: (state, action) => {
            state.errors = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // select categories thunk
            .addCase(selectCategoryListAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(selectCategoryListAsyncThunk.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.categories = action.payload;
            })
            .addCase(selectCategoryListAsyncThunk.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error.message;
            })

            // store category api
            .addCase(storeCategoryAsyncThunk.pending, (state) => {
                state.store_status = 'loading';
            })
            .addCase(storeCategoryAsyncThunk.fulfilled, (state, action) => {
                state.store_status = 'succeeded';
                state.categories.push(action.payload);
                state.selected_category = action.payload;
            })
            .addCase(storeCategoryAsyncThunk.rejected, (state, action) => {
                state.store_status = 'error';
                // နှစ်ခုတည်းက တစ်ခု ယူရမှာ ။
                try {
                    // try multiple errors
                    state.errors = JSON.parse(action.error.message); // Store the error message
                    const firstKey = Object.keys(JSON.parse(action.error.message))[0];
                    const firstItem = JSON.parse(action.error.message)[firstKey][0];

                    state.error = firstItem;
                }
                catch (err) {
                    // error message
                    state.error = action.error.message; // Store the error message
                }
            })

            // update category api
            .addCase(updateCategoryAsyncThunk.pending, (state) => {
                state.update_status = 'loading';
            })
            .addCase(updateCategoryAsyncThunk.fulfilled, (state, action) => {
                state.update_status = 'succeeded';
                // find and update local category state
                state.selected_category = action.payload;
            })
            .addCase(updateCategoryAsyncThunk.rejected, (state, action) => {
                // handle multiple errors for remote validation
                state.update_status = 'error';
                try {
                    // try multiple errors
                    state.errors = JSON.parse(action.error.message); // Store the error message
                }
                catch (err) {
                    // error message
                    state.error = action.error.message; // Store the error message
                }
            })

            // delete category api
            .addCase(deleteCategoryAsyncThunk.pending, (state) => {
                state.delete_status = 'loading';
            })
            .addCase(deleteCategoryAsyncThunk.fulfilled, (state, action) => {
                state.delete_status = 'succeeded';
                state.categories = state.categories.filter(category => category.id !== action.payload.id);
            })
            .addCase(deleteCategoryAsyncThunk.rejected, (state, action) => {
                // handle multiple errors for remote validation
                state.delete_status = 'error';
                state.error = action.error.message; // Store the error message
                try {
                    state.errors = JSON.parse(action.error.message); // Store the error message
                }
                catch (error) {
                    state.errors = {};
                }
            });
    },
});

// actions
export const {
    setCategories,
    setSelectedCategory, updateSelectedCategory,
    setCategoryStatus, setCategoryStoreStatus, setCategoryUpdateStatus, setCategoryDeleteStatus,
    setCategoryErrors
} = categorySlice.actions;

// selectors
/* Global State / Combined Reducers ကနေ select လုပ်နေတယ်ဆိုတာ သတိကပ်ထားရမယ် */
// main data (၂) မျိုး
export const selectCategoryList = (state) => state.categories.categories;
export const selectSelectedCategory = (state) => state.categories.selected_category;
// CRUD Status (၄)​ မျိုး 
export const selectCategoryStatus = (state) => state.categories.status;
export const selectCategoryStoreStatus = (state) => state.categories.store_status;
export const selectCategoryUpdateStatus = (state) => state.categories.update_status;
export const selectCategoryDeleteStatus = (state) => state.categories.delete_status;
// Error (၂)​ မျိုး
export const selectCategoryError = (state) => state.categories.error;
export const selectCategoryErrors = (state) => state.categories.errors;

// export reducer
export default categorySlice.reducer;